import React, { useState,useEffect } from 'react'
import { Switch,Route,Link } from 'react-router-dom'
import firebase from '../firebase'

import Loader from './Loader';
import Dashboard from './dashboard/Dashboard';
import Hotels from './hotels/Hotels';
import SingleHotel from './single-hotel/SingleHotel';
import Rooms from './rooms/Rooms';
import Tracing from './tracing/Tracing';
import Messages from './messages/Messages';
import Suscriptions from './suscriptions/Suscriptions';
import Transactions from './transactions/Transactions';

const App = (props) => {

    const [isInitialized,setIsInitialized] = useState(false)

    useEffect(() => {
        firebase.isInitialized().then(user => {
            if (user) {
                firebase.userValidator(user.uid).then(isActive => {
                    if (isActive) {
                        setIsInitialized(true)
                    } else {
                        alert('Usuario no autorizado, solicita que te autorice tu administrador')
                        firebase.logout().then(() => {
                            props.history.replace('/iniciar-sesion')
                        })
                    }
                })
            } else {
                props.history.replace('/iniciar-sesion')
            }
        })
    },[props.history])

    const loc = props.location.pathname.split('/')[1]

    return isInitialized === true ? (
        <div className="app">
            <div className="sidenav">
                <div className="title">
                    <h3>Administración Grupo Hotelero Latinoamérica</h3>
                </div>
                <ul>
                    <Link className={loc === '' ? 'active' : ''} to="/">
                        <i className="material-icons">dashboard</i>
                        <span>Dashboard</span>
                    </Link>
                    <Link className={loc === 'hoteles' ? 'active' : ''} to="/hoteles">
                        <i className="material-icons">business</i>
                        <span>Hoteles</span>
                    </Link>
                    <Link className={loc === 'habitaciones' ? 'active' : ''} to="/habitaciones">
                        <i className="material-icons">meeting_room</i>
                        <span>Habitaciones</span>
                    </Link>
                    <Link className={loc === 'seguimiento' ? 'active' : ''} to="/seguimiento">
                        <i className="material-icons">assignment</i>
                        <span>Seguimiento</span>
                    </Link>
                    <Link className={loc === 'mensajes' ? 'active' : ''} to="/mensajes">
                        <i className="material-icons">message</i>
                        <span>Mensajes</span>
                    </Link>
                    <Link className={loc === 'suscripciones' ? 'active' : ''} to="/suscripciones">
                        <i className="material-icons">assignment_ind</i>
                        <span>Suscripciones</span>
                    </Link>
                    <Link className={loc === 'transacciones' ? 'active' : ''} to="/transacciones">
                        <i className="material-icons">credit_card</i>
                        <span>Transacciones</span>
                    </Link>
                    <Link className={loc === 'usuarios' ? 'active' : ''} to="/usuarios">
                        <i className="material-icons">people</i>
                        <span>Usuarios</span>
                    </Link>
                    <li onClick={signout}>
                        <i className="material-icons">exit_to_app</i>
                        <span>Cerrar Sesión</span>
                    </li>
                </ul>
            </div>
            <div className="app__container">
                <Switch>
                    <Route path="/" exact={true} component={Dashboard} />
                    <Route path="/hoteles" exact={true} component={Hotels} />
                    <Route path="/hoteles/:id" exact={true} component={SingleHotel} />
                    <Route path="/habitaciones" exact={true} component={Rooms} />
                    <Route path="/seguimiento" exact={true} component={Tracing} />
                    <Route path="/mensajes" exact={true} component={Messages} />
                    <Route path="/suscripciones" exact={true} component={Suscriptions} />
                    <Route path="/transacciones" exact={true} component={Transactions} />
                </Switch>
            </div>
        </div>
    ) : <Loader />

    async function signout() {
        try {
            firebase.logout().then(() => {
                props.history.replace('/iniciar-sesion')
            })
        } catch (error) {
            alert(error.message)
        } 
    }
}

export default App