import React from 'react'

const Suscriptions = () => {

    return (
        <div className="suscriptions">
            <table>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Email</th>
                        <th>Teléfono</th>
                    </tr>
                </thead>
            </table>
        </div>
    )
}

export default Suscriptions