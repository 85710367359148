import React from 'react'

const Messages = () => {
    
    return (
        <div className="messages">
            <ul>
                <h2>Pendientes</h2>
            </ul>
            <ul>
                <h2>Atendidos</h2>
            </ul>
        </div>
    )
}


export default Messages