import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import firebase from '../firebase'

const LogIn = (props) => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    useEffect(() => {
        firebase.isInitialized().then(val => {
            if (val) {
                props.history.replace('/')
            }
        })
    })

    return (
        <div className="log-in">
            <div className="log-in__container">
                <div className="logo">
                    <img src="/images/logo.png" alt="GHL"/>
                </div>
                <form onSubmit={e => e.preventDefault() && false}>
                    <div className="input-container">
                        <input value={email} placeholder="Email" autoComplete="off" type="email" onChange={e => setEmail(e.target.value)} />
                    </div>
                    <div className="input-container">
                        <input value={password} placeholder='Contraseña' autoComplete="off" type="password" onChange={e => setPassword(e.target.value)} />
                    </div>
                    <div className="input-container">
                        <button onClick={log} type="submit">Iniciar Sesión</button>
                    </div>
                </form>
                <Link className="btn" to='/registro'>Registrarse</Link>
                {/* <Link className="link" to="/">{props.language === 'en' ? 'Recover your password' : '¿Problemas para iniciar sesión?'}</Link> */}
            </div>
        </div>
    )
    async function log() {
        try {
            await firebase.login(email,password).then(() => {
                props.history.replace('/')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default LogIn;