import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import firebase from '../../firebase'

const Hotels = () => {

    const [hotels,setHotels] = useState([])
    const [name,setName] = useState('')

    useEffect(() => {
        firebase.getCollectionRealTime('hotels').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setHotels(data)
        })
    },[])

    return (
        <div className="hotels">
            <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Nombre de Hotel</label>
                    <input value={name} onChange={e => setName(e.target.value)} placeholder="Nombre de Hotel" type="text"/>
                </div>
                <div className="input-container">
                    <button onClick={add} type="submit">Agregar</button>
                </div>
            </form>
            <div className="hotels__container">
                <ul>{
                    Object.keys(hotels).map(key => <Link key={key} to={`/hoteles/${hotels[key].id}`}>
                        <i className="material-icons">chevron_right</i>
                        <span>{hotels[key].name}</span>
                    </Link>)
                }</ul>
            </div>
        </div>
    )

    async function add () {
        try {
            const item = {
                name
            }
            firebase.simpleAdd(item,'hotels').then(() => {
                alert('Hotel agregado, comienza a editarlo')
                setName('')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default Hotels