import React from 'react'
import numeral from 'numeral'

const Dashboard = () => {

    return (
        <div className="dashboard">
            <ul>
                <li>
                    <span>Hoteles</span>
                    <b>{numeral(0).format('0.00')}</b>
                </li>
                <li>
                    <span>Habitaciones</span>
                    <b>{numeral(0).format('0.00')}</b>
                </li>
                <li>
                    <span>Total Ventas</span>
                    <b>{numeral(0).format('$0,0.00')}</b>
                </li>
                <li>
                    <span>Usuarios</span>
                    <b>{numeral(0).format('0.00')}</b>
                </li>
            </ul>
        </div>
    )
}

export default Dashboard