import React, { useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import firebase from '../../firebase'
import _ from 'lodash'

const SingleHotel = (props) => {

    const id = props.match.params.id
    const [rooms,setRooms] = useState([])
    const [hotel,setHotel] = useState({})
    const [cover,setCover] = useState(undefined)
    const [isLoading,setIsLoading] = useState(false)
    const [description,setDescription] = useState('')
    const [icon,setIcon] = useState('')
    const [roomName,setRoomName] = useState('')
    const [roomNumber,setRoomNumber] = useState('')

    useEffect(() => {
        firebase.getDocumentRealTime('hotels',id).onSnapshot(snap => {
            setHotel(snap.data())
        })
        firebase.getCollectionRealTime('rooms').where('hotelId','==',id).onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setRooms(data)
        })
    },[])

    const services = hotel.services ? hotel.services : []

    const orderedRooms = _.orderBy(rooms, ['number','name'], ['asc','desc']);

    return (
        <div className="single-hotel">
            <div className="input-container">
                <label>Nombre de Hotel</label>
                <input defaultValue={hotel.name} onChange={e => firebase.update('hotels', id, 'name', e.target.value)} type="text"/>
            </div>
            <div className="input-container">
                <label>Dirección</label>
                <input defaultValue={hotel.address} onChange={e => firebase.update('hotels', id, 'address', e.target.value)} type="text"/>
            </div>
            <div className="input-container">
                <label>Precio mínimo</label>
                <input defaultValue={hotel.minPrice} onChange={e => firebase.update('hotels', id, 'minPrice', Number(e.target.value))} type="number"/>
            </div>
            <div className="input-container">
                <label>URL de mapa</label>
                <input defaultValue={hotel.map} onChange={e => firebase.update('hotels', id, 'map', e.target.value)} type="text"/>
            </div>
            <div style={{ flex: '0 0 100%' }} className="input-container">
                <label>Descripción</label>
                <textarea rows="10" value={hotel.description} onChange={e => firebase.update('hotels', id, 'description', e.target.value)} />
            </div>
            <form onSubmit={e => e.preventDefault() && false}>
                <picture>
                    <img src={hotel.cover} alt={hotel.name}/>
                </picture>
                <div className="input-container">
                    <label>Portada</label>
                    <input onChange={e => setCover(e.target.files[0])} type="file" />
                </div>
                <div className="input-container">
                    <button disabled={isLoading} onClick={handleCover} type="submit">{isLoading === 'false' ? 'Establecer portada' : 'Cargando'}</button>
                </div>
            </form>
            <div className="input-container">
                <label>¿Hotel Activo?</label>
                <select value={hotel.isActive} onChange={e => firebase.update('hotels', id, 'isActive', e.target.value)}>
                    <option value="false">No</option>
                    <option value="true">Si</option>
                </select>
            </div>
            <section>
                <form onSubmit={e => e.preventDefault() && false}>
                    <h3>Servicios incluidos</h3>
                    <div className="input-container">
                        <label>Ícono</label>
                        <input placeholder="Ícono" onChange={e => setIcon(e.target.value)} value={icon} type="text"/>
                    </div>
                    <div className="input-container">
                        <label>Descripción de Servicio</label>
                        <input placeholder="Descripción de Servicio" onChange={e => setDescription(e.target.value)} value={description} type="text"/>
                    </div>
                    <div className="input-container">
                        <button onClick={addService} type="submit">Agregar</button>
                    </div>
                </form>
                <ul>{
                    Object.keys(services).map(key => <li key={key}>
                        <button onClick={() => removeService(key)}><i className="material-icons">delete</i></button>
                        <i className="material-icons">{services[key].icon}</i>
                        <span>{services[key].description}</span>
                    </li>)
                }</ul>
            </section>
            <section>
                <form onSubmit={e => e.preventDefault() && false}>
                    <h3>Habitaciones</h3>
                    <div className="input-container">
                        <label>Nombre / Tipo de Habitación</label>
                        <input placeholder="Nombre / Tipo de Habitación" onChange={e => setRoomName(e.target.value)} value={roomName} type="text"/>
                    </div>
                    <div className="input-container">
                        <label>Número de Habitación</label>
                        <input placeholder="Número de Habitación" onChange={e => setRoomNumber(e.target.value)} value={roomNumber} type="text"/>
                    </div>
                    <div className="input-container">
                        <button onClick={addRoom} type="submit">Agregar</button>
                    </div>
                </form>
                <table>
                    <thead>
                        <tr>
                            <th>Número de Habitación</th>
                            <th>Tipo de Habitación</th>
                            <th>¿Está disponible?</th>
                            <th>Ver</th>
                        </tr>
                    </thead>
                    <tbody>{
                        Object.keys(orderedRooms).map(key => <tr key={key}>
                            <td>{orderedRooms[key].number}</td>
                            <td>{orderedRooms[key].name}</td>
                            <td className="center">{orderedRooms[key].isAvailable}</td>
                            <td className="center"><Link to={`/habitaciones/${orderedRooms[key].id}`}><i className="material-icons">chevron_right</i></Link></td>
                        </tr>)
                    }</tbody>
                </table>
            </section>
        </div>
    )

    async function addRoom () {
        try {
            const item = {
                name: roomName,
                number: roomNumber,
                isAvailable: 'Si',
                hotelId: id
            }
            firebase.simpleAdd(item,'rooms').then(() => {
                setRoomName('')
                setRoomNumber('')
            })
        } catch (error) {
            alert(error.message)
        }
    }

    async function addService () {
        try {
            var newServices = [...services]
            newServices.push({ icon,description })
            firebase.update('hotels',id,'services',newServices).then(() => {
                alert('Servicio agregado')
                setIcon('')
                setDescription('')
            })
        } catch (error) {
            alert(error.message)
        }
    }

    async function removeService (key) {
        try {
            var newServices = [...services]
            delete newServices[key]
            firebase.update('hotels',id,'services',_.filter(newServices, o => {
                return o
            }))
        } catch (error) {
            alert(error.message)
        }
    }
    async function handleCover () {
        try {
            setIsLoading(true)
            firebase.updateImage('hotels',id,'cover',cover).then(() => {
                alert('Imagen agregada')
                setIsLoading(false)
            })
        } catch (error) {
            alert(error.message)
            setIsLoading(false)
        }
    }
}

export default SingleHotel