import React from 'react';

const Loader = () => {
    return (
        <div className="loader">
            <i className="material-icons">cached</i>
        </div>
    )
}

export default Loader;