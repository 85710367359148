import React, { useState,useEffect } from 'react';
// import { Link } from 'react-router-dom';
import firebase from '../firebase';

const Regist = (props) => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [name, setName] = useState('')

    useEffect(() => {
        firebase.isInitialized().then(val => {
            if (val) {
                props.history.replace('/')
                return null
            }
        })
    })

    return (
        <div className="regist">
            <div className="regist__container">
                <div className="logo">
                    <img src="/images/logo.png" alt="GHL"/>
                </div>
                <form onSubmit={e => e.preventDefault() && false}>
                    <div className="input-container">
                        <label>Nombre</label>
                        <input value={name} placeholder='Nombre' autoComplete="off" type="text" onChange={e => setName(e.target.value)} />
                    </div>
                    <div className="input-container">
                        <label>Email</label>
                        <input value={email} placeholder="Email" autoComplete="off" type="email" onChange={e => setEmail(e.target.value)} />
                    </div>
                    <div className="input-container">
                        <label>Contraseña</label>
                        <input value={password} placeholder='Contraseña' autoComplete="off" type="password" onChange={e => setPassword(e.target.value)} />
                    </div>
                    <button onClick={reg} type="submit">Registrarme</button>
                </form>
            </div>
        </div>
    )

    async function reg() {
        try {
            await firebase.register(name,email,password)
            props.history.replace('/')
        } catch (error) {
            alert(error.message)
        }
    }
}

export default Regist;