import app from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

import moment from 'moment';

const config = {
    apiKey: "AIzaSyBzQzqEP4Rez3m9VLT0-wvrSoKen3yuj_8",
    authDomain: "grupo-hotelero-lationamerica.firebaseapp.com",
    databaseURL: "https://grupo-hotelero-lationamerica.firebaseio.com",
    projectId: "grupo-hotelero-lationamerica",
    storageBucket: "grupo-hotelero-lationamerica.appspot.com",
    messagingSenderId: "583413113503",
    appId: "1:583413113503:web:50f6ead8c6a21d82"
}

class Firebase {
	constructor() {
		app.initializeApp(config)
		this.auth = app.auth()
        this.firestore = app.firestore()
        this.storage = app.storage()
	}

	login(email, password) {
		return this.auth.signInWithEmailAndPassword(email, password)
	}

	logout() {
		return this.auth.signOut()
	}

	async register(name, email, password) {
        const timestamp = moment().valueOf()
		await this.auth.createUserWithEmailAndPassword(email, password)
		return this.auth.currentUser.updateProfile({
			displayName: name
		}).then(() => {
            return this.firestore.doc(`users/${this.auth.currentUser.uid}`).set({
                name,
                id: this.auth.currentUser.uid,
                isActive: false,
                email,
                password,
                timestamp
            })
        })
    }

    userValidator(id) {
        return new Promise(resolve => {
            this.firestore.doc(`users/${id}`).onSnapshot(snap => {
                const user = snap.data();
                resolve(user.isActive)
            });
        });
    }

	isInitialized() {
		return new Promise(resolve => {
			this.auth.onAuthStateChanged(resolve)
		})
    }
    
    getUser() {
        return new Promise(resolve => {
            this.firestore.doc(`users/${this.auth.currentUser.uid}`).onSnapshot(snap => {
                const user = snap.data()
                resolve(user)
            })
        })
    }

    async simpleAdd(item,col) {
        const ref = this.firestore.collection(col).doc()
        item.id = ref.id
        item.timestamp = moment().valueOf()
        item.userId = this.auth.currentUser.uid
        await ref.set(item);
        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            col,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Agregar',
            what: ref.id,
            userId: this.auth.currentUser.uid
        };
        return movesRef.set(move);
    }
    async addPageSection(item,id,section) {
        const ref = this.firestore.collection('projects').doc(id).collection(section).doc()
        item.id = ref.id
        item.timestamp = moment().valueOf()
        item.userId = this.auth.currentUser.uid
        await ref.set(item);
        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            col: 'projects',
            section,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Agregar',
            what: ref.id,
            userId: this.auth.currentUser.uid
        };
        return movesRef.set(move);
    }

    async addCourse(item,image) {
        const uploadFile = (file,id) => {
            return new Promise((resolve,reject) => {
                const ext = file.name.split('.').pop()
                const fileName = `${id}.${ext}`;
                const uploadFile = this.storage.ref(`courses`).child(fileName).put(file);
                uploadFile.on('state_changed', snapshot => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    return progress
                }, error => {
                    reject(error)
                }, () => {
                    uploadFile.snapshot.ref.getDownloadURL().then(url => {
                        resolve(url)
                    })
                });
            })
        }
        const ref = this.firestore.collection('courses').doc()
        const url_1 = await uploadFile(image, ref.id);
        item.cover = url_1;
        item.id = ref.id;
        item.timestamp = moment().valueOf();
        return ref.set(item);
    }

    getCollectionOnce(col) {
        const data = this.firestore.collection(col).get()
        return data
    }
    getCollectionRealTime(col) {
        const data = this.firestore.collection(col)
        return data
    }
    getDocumentOnce(col,doc) {
        const data = this.firestore.collection(col).doc(doc).get()
        return data
    }
    getDocumentRealTime(col,doc) {
        const data = this.firestore.collection(col).doc(doc)
        return data
    }
    getMyBets() {
        const data = this.firestore.collection('bets').where('userId','==',this.auth.currentUser.uid)
        return data
    }
    update(col,id,index,value) {
        return this.firestore.collection(col).doc(id).update({ [index]: value });
    }
    updatePageSection(id,section,itemId,index,value) {
        return this.firestore.collection('projects').doc(id).collection(section).doc(itemId).update({ [index]: value})
    }
    async updateImage(col,id,index,file) {
        const uploadFile = (file,id) => {
            return new Promise((resolve,reject) => {
                const ext = file.name.split('.').pop()
                const fileName = `${id}.${ext}`;
                const uploadFile = this.storage.ref(col).child(fileName).put(file);
                uploadFile.on('state_changed', snapshot => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    return progress
                }, error => {
                    reject(error)
                }, () => {
                    uploadFile.snapshot.ref.getDownloadURL().then(url => {
                        resolve(url)
                    })
                });
            })
        }
        const ref = this.firestore.collection(col).doc(id)
        const url = await uploadFile(file, id);
        return ref.update({ [index]:url })
    }

    async delete(col,doc,data) {
        const ref = this.firestore.collection(col).doc(doc)
        await ref.delete();
        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            col,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Borrar',
            what: ref.id,
            userId: this.auth.currentUser.uid,
            data
        };
        return movesRef.set(move);
    }
    async deleteSection(id,section,itemId,data) {
        const ref = this.firestore.collection('projects').doc(id).collection(section).doc(itemId)
        await ref.delete()
        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            col: 'projects',
            section,
            itemId,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Borrar',
            what: ref.id,
            userId: this.auth.currentUser.uid,
            data
        };
        return movesRef.set(move);
    }
}

export default new Firebase()

// const uploadFile = (file,type) => {
//     return new Promise((resolve,reject) => {
//         const ext = file.name.split('.').pop()
//         const fileName = `${this.auth.currentUser.uid}-${type}.${ext}`;
//         const uploadFile = this.storage.ref(`users/${this.auth.currentUser.uid}`).child(fileName).put(file);
//         uploadFile.on('state_changed', snapshot => {
//             const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//             return progress
//         }, error => {
//             reject(error)
//         }, () => {
//             uploadFile.snapshot.ref.getDownloadURL().then(url => {
//                 resolve(url)
//             })
//         });
//     })
// }