import React from 'react'

const Transactions = () => {

    return (
        <div className="transactions">
            <table>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Cliente</th>
                        <th>Monto</th>
                        <th>Hotel</th>
                        <th>Habitación</th>
                        <th>Detalle</th>
                    </tr>
                </thead>
            </table>
        </div>
    )
}

export default Transactions