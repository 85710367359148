import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import firebase from '../../firebase'
import _ from 'lodash'

const Item = (props) => {

    const {data} = props
    const [hotel,setHotel] = useState({})

    useEffect(() => {
        firebase.getDocumentOnce('hotels',data.hotelId).then(snap => {
            setHotel(snap.data())
        })
    },[])

    return (
        <tr>
            <td>{hotel.name}</td>
            <td>{data.number}</td>
            <td>{data.name}</td>
            <td>{data.isAvailable}</td>
            <td className="center"><Link to={`/habitaciones/${data.id}`}><i className="material-icons">chevron_right</i></Link></td>
        </tr>
    )
}

const Rooms = () => {

    const [rooms,setRooms] = useState([])

    useEffect(() => {
        firebase.getCollectionRealTime('rooms').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setRooms(data)
        })
    },[])

    return (
        <div className="rooms">
            <table>
                <thead>
                    <tr>
                        <th>Hotel</th>
                        <th>Número de Habitación</th>
                        <th>Tipo de Habitación</th>
                        <th>¿Está disponible?</th>
                        <th>Ver más</th>
                    </tr>
                </thead>
                <tbody>{
                    Object.keys(rooms).map(key => <Item key={key} data={rooms[key]} />)
                }</tbody>
            </table>
        </div>
    )
}

export default Rooms