import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import './index.css';
import * as serviceWorker from './serviceWorker';

import App from './components/App';
import LogIn from './components/Login';
import Regist from './components/Regist';

const routes = (
    <BrowserRouter>
        <Switch>
            <Route path="/iniciar-sesion" component={LogIn} exact={true} />
            <Route path="/registro" component={Regist} exact={true} />
            <Route path="/" component={App} />
        </Switch>
    </BrowserRouter>
)

ReactDOM.render(routes, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
